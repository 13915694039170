import React from 'react';
import { HStack, styled } from '../../../styled-system/jsx';

export const BackLink: React.FC<{
  onClick: () => void;
  children: React.ReactNode;
}> = ({ onClick, children }) => {
  return (
    <styled.a color={'liff.text.blue'} onClick={onClick} cursor={'pointer'}>
      <HStack
        alignItems={'center'}
        _hover={{
          textDecoration: 'underline',
        }}
      >
        <BackArrow />
        {children}
      </HStack>
    </styled.a>
  );
};

const BackArrow: React.FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8083 3.83169L13.3333 2.35669L5 10.69L13.3333 19.0234L14.8083 17.5484L7.95 10.69L14.8083 3.83169Z"
      fill="#1565C4"
    />
  </svg>
);
