import React from 'react';
import { styled } from '../../../styled-system/jsx';
import { SystemProperties } from '../../../styled-system/types/style-props';

export const Spinner: React.FC<SystemProperties> = (prop) => {
  return (
    <styled.span
      minWidth={'1.5em'}
      minHeight={'1.5em'} // size指定をもう少しいい感じにしたい
      border={'5px solid #FFF'}
      borderBottomColor={'#22CC22'}
      borderRadius={'50%'}
      display={'inline-block'}
      boxSizing={'border-box'}
      animation={'spin'}
      {...prop}
    />
  );
};
