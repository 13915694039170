import liff from '@line/liff';
import { LiffAppType, ProviderAccount } from '@pochico/shared';
import React from 'react';

import { createContext, FC } from 'react';
import { useFetchProviderAccount } from './useFetchProviderAccount';
import { useLiffInitialization } from './useLiffInitialization';

type AuthState = {
  providerAccount: ProviderAccount | undefined;
  accessToken: string | undefined;
  status: 'idle' | 'loading' | 'success' | 'error'; // useQueryのstatusと同じ
  isLineLoggedIn: boolean;
  lineLogin: (option?: { redirectUrl?: string }) => Promise<void>;
};
const initialAuthState: AuthState = {
  providerAccount: undefined,
  accessToken: undefined,
  status: 'idle',
  isLineLoggedIn: false,
  lineLogin: () => Promise.resolve(),
};

export const AuthStateContext: React.Context<AuthState> =
  createContext<AuthState>({
    ...initialAuthState,
  });

export const AuthProviderContainer: FC<{
  providerAccountId: string;
  liffAppType: LiffAppType;
  children: React.ReactNode;
}> = ({ providerAccountId, children, liffAppType }) => {
  const state = useLiffInternal({ providerAccountId, liffAppType });

  return (
    <AuthStateContext.Provider value={state}>
      {children}
    </AuthStateContext.Provider>
  );
};

const useLiffInternal = ({
  providerAccountId,
  liffAppType,
}: {
  providerAccountId: string;
  liffAppType: LiffAppType;
}) => {
  const providerAccountQuery = useFetchProviderAccount(providerAccountId);
  const providerAccount = providerAccountQuery.data?.providerAccount;
  const liffInitializationQuery = useLiffInitialization(
    providerAccount,
    liffAppType
  );
  const accessToken = liffInitializationQuery.data?.accessToken;

  const lineLogin = React.useCallback(
    async (option?: { redirectUrl?: string }) => {
      if (liff.isLoggedIn()) {
        console.log(`already logged in`);
        return;
      }
      return liff.ready.then(() => {
        liff.login({
          redirectUri: option?.redirectUrl || location.href,
        });
      });
    },
    []
  );

  const status = (() => {
    if (providerAccountQuery.status === 'success') {
      return liffInitializationQuery.status;
    } else {
      return providerAccountQuery.status;
    }
  })();

  return {
    providerAccount,
    status,
    accessToken,
    lineLogin,
    isLineLoggedIn: !!accessToken,
  };
};
