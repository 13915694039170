import * as Sentry from '@sentry/react';
import React from 'react';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const initializeSentry = () => {
  if (!import.meta.env.VITE_SENTRY) {
    return;
  }
  const options: Sentry.BrowserOptions = {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: __COMMIT_HASH__,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          import.meta.env.VITE_API_ENDPOINT,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
  };
  Sentry.init(options);
  console.log(`Sentry initialized`, { options });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
