import type { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import {
  AspectRatio,
  Box,
  Flex,
  HStack,
  VStack,
  styled,
} from '../../../../../styled-system/jsx';
import { SelectButton } from '../../../../style/button';
import { TextWithNewLine } from '../../../ui/TextWithNewLine';

export const SelectBookingMenuView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
  onSelected: (bookingMenu: BookingMenu) => void;
}> = ({ providerAccount, bookingMenus, onSelected }) => {
  return (
    <Flex
      w={{ base: '100vw', lg: 'fit-content' }}
      alignSelf={{ base: 'flex-start', lg: 'center' }}
      paddingX={'20px'}
      overflowX={'auto'}
      marginBottom={'20px'}
    >
      {bookingMenus.length > 0 ? (
        <HStack alignItems="flex-start" gap={2}>
          {bookingMenus.map((menu) => (
            <SelectableBookingMenu
              key={menu.id}
              providerAccount={providerAccount}
              bookingMenu={menu}
              onSelected={onSelected}
            />
          ))}
        </HStack>
      ) : (
        <>予約可能なメニューがありません。事業者にお問い合わせください。</>
      )}
    </Flex>
  );
};

const SelectableBookingMenu: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  onSelected: (bookingMenu: BookingMenu) => void;
}> = ({ bookingMenu, onSelected }) => {
  const onClick = React.useCallback(() => {
    onSelected(bookingMenu);
  }, [bookingMenu, onSelected]);

  return (
    <Flex
      direction={'column'}
      borderRadius={'17px'}
      boxShadow={'md'}
      w={'219px'}
      minH={'326px'}
      h={'fit-content'}
      border={'1px solid #DEDEDE'}
      backgroundColor={'white'}
    >
      {bookingMenu.imageUrl && (
        <Flex
          justifyContent={'center'}
          alignItems={'flex-start'}
          borderRadius={'17px'}
          w={'full'}
          // maxH={'169px'}
        >
          <Flex
            alignItems={'flex-start'}
            w={'full'}
            h={'full'}
            overflow={'hidden'}
            borderTopRadius={'17px'}
          >
            {bookingMenu.imageUrl && (
              <AspectRatio w={'full'} ratio={2 / 1}>
                <styled.img
                  // maxW={'full'}
                  // maxH={'full'}
                  src={bookingMenu.imageUrl}
                  alt={bookingMenu.name}
                />
              </AspectRatio>
            )}
          </Flex>
        </Flex>
      )}
      <VStack
        w={'full'}
        h={'full'}
        paddingTop={'15px'}
        padding={'15px'}
        alignItems={'left'}
        flexGrow={1}
        // overflow={'scroll'}
      >
        <Box fontWeight={'bold'} fontSize={20}>
          {bookingMenu.name}
        </Box>
        {bookingMenu.description && (
          <TextWithNewLine fontSize={13}>
            {bookingMenu.description}
          </TextWithNewLine>
        )}

        <SelectButton
          variant={'selectable'}
          marginTop={'auto'}
          onClick={onClick}
        >
          予約する
        </SelectButton>
      </VStack>
    </Flex>
  );
};
