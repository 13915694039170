import { useParams } from 'react-router-dom';
import { PochicoLiff } from '../components/liff/providerAccountId/PochicoLiff';
import { Spinner } from '../components/ui/Spinner';

export const PochicoLiffPage = () => {
  const params = useParams() as { providerAccountId: string };
  if (params && params.providerAccountId) {
    return <PochicoLiff providerAccountId={params.providerAccountId} />;
  } else {
    return <Spinner />;
  }
};
