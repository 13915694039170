// import { ChakraProvider } from '@chakra-ui/provider';
// import { ChakraProvider, extendBaseTheme } from '@chakra-ui/react';
// import { Button as ButtonTheme } from '@chakra-ui/theme/components';
import {
  QueryClient,
  QueryClientProvider,
  QueryClientProviderProps,
} from '@tanstack/react-query';
// import React from 'react';
import * as React from 'react';
// import { ReactQueryDevtools } from 'react-query/devtools';

// const theme = extendBaseTheme({
//   components: {
//     Button: ButtonTheme,
//   },
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
export const Providers: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const queryClient = React.useMemo(() => new QueryClient(), []);
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ChakraProvider theme={theme}> */}
      {
        children as QueryClientProviderProps['children'] /* なぜかエラーになるのでキャスト */
      }
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {/* </ChakraProvider> */}
    </QueryClientProvider>
  );
};
