import type { BookingMenu, ProviderAccount } from '@pochico/shared';
import { dateTimeStringWithWeekDay, setDayJSTime } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { Box, VStack } from '../../../../../styled-system/jsx';
import { BackLink } from '../../../ui/BackLink';
import { ButtonWithLoading } from '../../../ui/Button';
import { useLiff } from '../useLiff';
import { SpotDate, SpotTime } from './hooks';

export const ConfirmBeforeBooking: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  spotDate: SpotDate;
  spotTime: SpotTime['timeList'][number];
  isSubmitting: boolean;
  onConfirmed: () => void;
  onCancel: () => void;
}> = ({
  bookingMenu,
  spotDate,
  spotTime,
  isSubmitting,
  onConfirmed,
  onCancel,
}) => {
  const { isLineLoggedIn } = useLiff();
  return (
    <VStack alignItems={'center'} paddingX={'20px'} gap={'16px'}>
      <Box textAlign={'center'} width={'full'}>
        こちらの内容で予約します。よろしいですか？
      </Box>
      <VStack
        backgroundColor={'white'}
        borderRadius={'24px'}
        alignItems={'center'}
        padding={'20px'}
      >
        <Box fontSize={'2xl'}>{bookingMenu.name}</Box>
        <Box fontSize={'lg'}>
          {dateTimeStringWithWeekDay(
            setDayJSTime(dayjs(spotDate.date), spotTime.time)!
          )}
        </Box>
        <ButtonWithLoading
          marginTop={'16px'}
          borderRadius={'8px'}
          width={'full'}
          onClick={() => {
            onConfirmed();
          }}
          isLoading={isSubmitting}
        >
          {isLineLoggedIn ? '予約する' : 'LINEログインして予約する'}
        </ButtonWithLoading>
      </VStack>
      <Box
        textAlign={'left'}
        width={'full'}
        display={isSubmitting ? 'none' : undefined}
      >
        <BackLink onClick={onCancel}>時間選択に戻る</BackLink>
      </Box>
    </VStack>
  );
};
