import { cva } from '../../styled-system/css';
import { styled } from '../../styled-system/jsx';

const buttonStyle = cva({
  base: {
    display: 'flex',
    cursor: 'pointer',
    // borderWidth: '1px',
    // borderColor: 'black',
    borderRadius: '24px',
    textAlign: 'center',
    justifyContent: 'center',
    color: 'liff.text.white',
  },
  variants: {
    visual: {
      brand: {
        color: 'white',
        // colorScheme: 'green',
        bg: {
          base: 'green.500',
          _hover: 'green.400',
          _active: 'green.300',
        },
      },
      outline: { borderWidth: '1px', borderColor: 'green.500' },
      transparent: {
        bg: 'transparent',
      },
    },
    size: {
      sm: { padding: '4px', fontSize: '12px' },
      md: { paddingX: '8px', paddingY: '4px', fontSize: '16px' },
      lg: { padding: '8px', fontSize: '24px' },
    },
  },
  defaultVariants: {
    visual: 'brand',
    size: 'md',
  },
});

export const Button = styled('button', buttonStyle);

export const SelectButton = styled('button', {
  base: {
    paddingY: '8px',
    paddingX: '24px',
    fontSize: '16px',
    color: 'white',
    borderRadius: '8px',
  },
  variants: {
    variant: {
      selectable: {
        bg: {
          base: 'liff.button.selectable.base',
          _hover: 'liff.button.selectable.hover',
          _active: 'liff.button.selectable.active',
        },
        cursor: 'pointer',
      },
      notSelectable: {
        bg: {
          base: 'liff.button.notSelectable.base',
          _hover: 'liff.button.notSelectable.hover',
          _active: 'liff.button.notSelectable.active',
        },
        cursor: 'default',
      },
      cancel: {
        bg: {
          base: 'liff.button.cancel.base',
          _hover: 'liff.button.cancel.hover',
          _active: 'liff.button.cancel.active',
        },
        cursor: 'pointer',
      },
    },
  },
});
