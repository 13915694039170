import { Box, VStack } from '../../styled-system/jsx';
import { ToLP } from '../components/ToLP';

export const Page404 = () => {
  return (
    <VStack marginTop={'30px'}>
      <Box fontSize="2xl" fontWeight="bold" textAlign="center">
        404 NOT FOUND
      </Box>
      <ToLP />
    </VStack>
  );
};
