import { sleep } from '@pochico/shared';
import React from 'react';
import { HStack, styled } from '../../styled-system/jsx';
import { Spinner } from '../components/ui/Spinner';

export const ToLP = () => {
  const URL = `https://pochico.app`;
  React.useEffect(() => {
    sleep(3000).then(() => {
      window.location.href = URL;
    });
  }, [URL]);
  return (
    <HStack>
      <Spinner />
      <styled.a
        color={'liff.text.blue'}
        cursor={'pointer'}
        textDecoration={'underline'}
        href={URL}
      >
        LP
      </styled.a>
      へと遷移します...
    </HStack>
  );
};
