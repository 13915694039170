import { Box, BoxProps } from '../../../styled-system/jsx';

export const TextWithNewLine: React.FC<{ children: string } & BoxProps> = ({
  children,
  ...props
}) => (
  <Box
    maxWidth={'90vw'}
    {...props}
    dangerouslySetInnerHTML={{
      __html: children.replace(new RegExp('\\n', 'g'), '<br />'),
    }}
  ></Box>
);
