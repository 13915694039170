import { sleep, type BookingMenu, type ProviderAccount } from '@pochico/shared';

import React from 'react';
import { toast } from 'react-toastify';
import { Box, VStack } from '../../../../../styled-system/jsx';
import { Spinner } from '../../../ui/Spinner';
import { SuspenseWithErrorBoundary } from '../../../ui/SuspenseWithErrorBoundary';
import { useLiff } from '../useLiff';
import { ConfirmBeforeBooking } from './ConfirmBeforeBooking';
import { CreateBookingProgress, ProgressStep } from './Progress';
import { SelectBookingMenuView } from './SelectBookingMenuView';
import { SelectSpotDateView } from './SelectSpotDateView';
import { SelectSpotTimeView } from './SelectSpotTimeView';
import { SpotDate, SpotTime, useStartBooking, useSubmitBooking } from './hooks';

const stateKey = 'createBookingState';
type CreateBookingState =
  | {
      selectedBookingMenu: BookingMenu | undefined;
      selectedSpotDate: undefined;
      selectedSpotTime: undefined;
      confirmed: false;
    }
  | {
      selectedBookingMenu: BookingMenu;
      selectedSpotDate: SpotDate;
      selectedSpotTime: undefined;
      confirmed: false;
    }
  | {
      selectedBookingMenu: BookingMenu;
      selectedSpotDate: SpotDate;
      selectedSpotTime: SpotTime['timeList'][number];
      confirmed: false;
    }
  | {
      selectedBookingMenu: BookingMenu;
      selectedSpotDate: SpotDate;
      selectedSpotTime: SpotTime['timeList'][number];
      confirmed: true;
    };

export const CreateBooking: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  const { status, lineLogin, accessToken } = useLiff();
  const startBookingQuery = useStartBooking(providerAccount);

  const [state, setState] = React.useState<CreateBookingState>(() => {
    const _state = localStorage.getItem(stateKey);
    if (_state) {
      console.log(`[CreateBooking] recovered from localStorage ${stateKey}`, {
        _state,
      });
      // LIFFアプリがなぜかpage reloadしてしまうのでここでremoveするとまずい
      // localStorage.removeItem(stateKey);
    }
    return _state
      ? JSON.parse(_state)
      : {
          selectedBookingMenu: undefined,
          selectedSpotDate: undefined,
          selectedSpotId: undefined,
          confirmed: false,
        };
  });
  const { selectedBookingMenu, selectedSpotDate, selectedSpotTime, confirmed } =
    state;
  const mutation = useSubmitBooking();

  const onSubmit = React.useCallback(
    (
      providerAccount: ProviderAccount,
      bookingMenu: BookingMenu,
      spotId: string
    ) => {
      if (!accessToken) {
        localStorage.setItem(
          stateKey,
          JSON.stringify({
            selectedBookingMenu,
            selectedSpotDate,
            selectedSpotTime,
            confirmed: true,
          })
        );
        return lineLogin();
      }
      localStorage.removeItem(stateKey);

      return mutation
        .mutateAsync({ providerAccount, bookingMenu, spotId, accessToken })
        .then((res) => {
          if (!res || res?.error) {
            toast.error(`予約に失敗しました: ${res?.error}`);
            setState((state) => ({
              ...state,
              confirmed: false,
            }));
            return;
          } else if (res && res.successMessage) {
            toast.success(() => (
              <Box
                dangerouslySetInnerHTML={{
                  __html: res.successMessage.replace('\n', '<br />'),
                }}
              />
            ));
            return sleep(1000).then(() => {
              setState({
                selectedBookingMenu: undefined,
                selectedSpotDate: undefined,
                selectedSpotTime: undefined,
                confirmed: false,
              });
            });
          }
        });
    },
    [
      accessToken,
      lineLogin,
      mutation,
      selectedBookingMenu,
      selectedSpotDate,
      selectedSpotTime,
    ]
  );
  const onClickProgress = React.useCallback(
    (clickedTab: ProgressStep) => {
      switch (clickedTab) {
        case 'selectBookingMenu':
          setState({
            selectedBookingMenu: undefined,
            selectedSpotDate: undefined,
            selectedSpotTime: undefined,
            confirmed: false,
          });
          break;
        case 'selectSpotDate':
          setState({
            selectedBookingMenu: selectedBookingMenu,
            selectedSpotDate: undefined,
            selectedSpotTime: undefined,
            confirmed: false,
          });
          break;
        case 'selectSpotTime':
          if (selectedBookingMenu && selectedSpotDate) {
            setState({
              selectedBookingMenu: selectedBookingMenu,
              selectedSpotDate: selectedSpotDate,
              selectedSpotTime: undefined,
              confirmed: false,
            });
          } else {
            // 本来は不要だが型合わせのため
            setState({
              selectedBookingMenu: selectedBookingMenu,
              selectedSpotDate: undefined,
              selectedSpotTime: undefined,
              confirmed: false,
            });
          }
          break;
      }
    },
    [selectedBookingMenu, selectedSpotDate]
  );

  React.useEffect(() => {
    if (
      startBookingQuery.data &&
      'useBookingMenus' in startBookingQuery.data &&
      !startBookingQuery.data.useBookingMenus
    ) {
      setState({
        selectedBookingMenu: startBookingQuery.data.bookingMenu,
        selectedSpotDate: undefined,
        selectedSpotTime: undefined,
        confirmed: false,
      });
    }
  }, [startBookingQuery.data]);

  const [submitRetryStatus, setSubmitRetryStatus] = React.useState<
    'initial' | 'submitting' | 'done'
  >('initial');
  React.useEffect(() => {
    // LINE Loginが完了したら、再度予約を実行する
    if (
      localStorage.getItem(stateKey) &&
      submitRetryStatus === 'initial' &&
      status === 'success' &&
      accessToken &&
      selectedBookingMenu &&
      selectedSpotDate &&
      selectedSpotTime &&
      confirmed
    ) {
      console.log(`[CreateBooking] onSubmit called after LINE login`, {
        submitRetryStatus,
        status,
        accessToken,
        selectedBookingMenu,
        selectedSpotDate,
        selectedSpotTime,
        confirmed,
      });
      setSubmitRetryStatus('submitting');
      onSubmit(
        providerAccount,
        selectedBookingMenu,
        selectedSpotTime.spotId
      ).finally(() => {
        setSubmitRetryStatus('done');
      });
    }
  }, [
    accessToken,
    confirmed,
    onSubmit,
    providerAccount,
    selectedBookingMenu,
    selectedSpotDate,
    selectedSpotTime,
    status,
    submitRetryStatus,
  ]);

  if (startBookingQuery.status === 'loading' || !startBookingQuery.data) {
    return <Spinner />;
  } else if (startBookingQuery.error || 'error' in startBookingQuery.data) {
    return (
      <Box>
        エラーが発生しました
        <br />
        {`${startBookingQuery.error ?? JSON.stringify(startBookingQuery.data)}`}
      </Box>
    );
  } else if (submitRetryStatus === 'submitting') {
    // Confirm画面でloading状態にする
    // return (
    //   <VStack justifyContent="center" gap={10}>
    //     <CreateBookingProgress
    //       useBookingMenu={startBookingQuery.data.useBookingMenus}
    //       activeStep={'selectSpotTime'}
    //       onClick={onClickProgress}
    //     />
    //     <HStack alignItems={'center'} justifyContent={'center'}>
    //       <Spinner />
    //       <Box>予約中...</Box>
    //     </HStack>
    //   </VStack>
    // );
  }

  // 予約メニュー、日にち選択、時間選択が完了
  console.log(`isSubmitting`, {
    confirmed,
    submitRetryStatus,
    isLoading: mutation.isLoading,
  });
  if (selectedBookingMenu && selectedSpotDate && selectedSpotTime) {
    return (
      <VStack justifyContent="center" gap={'16px'}>
        {/* <CreateBookingProgress
          useBookingMenu={startBookingQuery.data.useBookingMenus}
          activeStep={'selectSpotTime'}
          onClick={onClickProgress}
        /> */}
        <SuspenseWithErrorBoundary>
          <ConfirmBeforeBooking
            providerAccount={providerAccount}
            bookingMenu={selectedBookingMenu}
            spotDate={selectedSpotDate}
            spotTime={selectedSpotTime}
            isSubmitting={
              confirmed ||
              submitRetryStatus === 'submitting' ||
              mutation.isLoading
            }
            onConfirmed={() => {
              setState({
                selectedBookingMenu,
                selectedSpotDate,
                selectedSpotTime,
                confirmed: true,
              });
              onSubmit(
                providerAccount,
                selectedBookingMenu,
                selectedSpotTime.spotId
              );
            }}
            onCancel={() => onClickProgress('selectSpotTime')}
          />
        </SuspenseWithErrorBoundary>
      </VStack>
    );
  }

  // 予約メニュー、日にち選択が完了
  if (selectedBookingMenu && selectedSpotDate) {
    return (
      <VStack justifyContent="center" gap={'16px'}>
        <CreateBookingProgress
          useBookingMenu={startBookingQuery.data.useBookingMenus}
          activeStep={'selectSpotTime'}
          onClick={onClickProgress}
        />
        <SuspenseWithErrorBoundary>
          <SelectSpotTimeView
            providerAccount={providerAccount}
            bookingMenu={selectedBookingMenu}
            spotDate={selectedSpotDate}
            onSelected={(spotTime) =>
              setState({
                selectedBookingMenu: selectedBookingMenu,
                selectedSpotDate: selectedSpotDate,
                selectedSpotTime: spotTime,
                confirmed: false,
              })
            }
            onCancel={() => onClickProgress('selectSpotDate')}
          />
        </SuspenseWithErrorBoundary>
      </VStack>
    );
  }

  // 予約メニュー選択が完了 or 予約メニューを使用しない場合
  if (selectedBookingMenu) {
    return (
      <VStack justifyContent="center" gap={'16px'}>
        <CreateBookingProgress
          useBookingMenu={startBookingQuery.data.useBookingMenus}
          activeStep={'selectSpotDate'}
          onClick={onClickProgress}
        />
        <SuspenseWithErrorBoundary>
          <SelectSpotDateView
            providerAccount={providerAccount}
            bookingMenu={selectedBookingMenu}
            onSelected={(spotDate) =>
              setState({
                selectedBookingMenu: selectedBookingMenu,
                selectedSpotDate: spotDate,
                selectedSpotTime: undefined,
                confirmed: false,
              })
            }
            onCancel={() => onClickProgress('selectBookingMenu')}
          />
        </SuspenseWithErrorBoundary>
      </VStack>
    );
  }

  // 予約メニューを使用する場合の予約メニューの選択
  if (startBookingQuery.data.useBookingMenus) {
    return (
      <VStack justifyContent="center" gap={'16px'}>
        <CreateBookingProgress
          useBookingMenu={startBookingQuery.data.useBookingMenus}
          activeStep={'selectBookingMenu'}
          onClick={onClickProgress}
        />
        <SuspenseWithErrorBoundary>
          <SelectBookingMenuView
            providerAccount={providerAccount}
            bookingMenus={startBookingQuery.data.bookingMenus}
            onSelected={(bookingMenu) =>
              setState({
                selectedBookingMenu: bookingMenu,
                selectedSpotDate: undefined,
                selectedSpotTime: undefined,
                confirmed: false,
              })
            }
          />
        </SuspenseWithErrorBoundary>
      </VStack>
    );
  }

  return <>不正なデータです</>;
};
