import { createBrowserRouter, Route } from 'react-router-dom';
import { ErrorPage } from './ErrorPage';
import { Page404 } from './pages/404';
import { PochicoLiffPage } from './pages/PochicoLiffPage';
import { SentryRoutes } from './sentry';

const Root = () => {
  return (
    // <Routes>
    <SentryRoutes>
      <Route
        path="/booking/:providerAccountId"
        element={<PochicoLiffPage />}
        errorElement={<ErrorPage />}
      />
      <Route path="*" element={<Page404 />} />
    </SentryRoutes>
    // </Routes>
  );
};

export const router = createBrowserRouter([
  {
    path: '*',
    Component: Root,
    errorElement: <ErrorPage />,
  },
]);
