import liff from '@line/liff';
import { LiffAppType, ProviderAccount } from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

export const useLiffInitialization = (
  providerAccount: ProviderAccount | undefined,
  liffAppType: LiffAppType
) => {
  const query = React.useCallback(() => {
    if (!providerAccount) {
      return;
    }
    const liffId = providerAccount.liff?.[liffAppType];
    if (!liffId) {
      console.error(`liffId is not found`, { providerAccount, liffAppType });
      return { accessToken: undefined };
    }
    return liff
      .init({ liffId })
      .then(() => liff.ready)
      .then(() => {
        const accessToken = liff.getAccessToken();
        console.log('useLiffInternal', {
          _accessToken: accessToken,
          liffId,
        });
        if (accessToken) {
          return { accessToken };
        }
        return { accessToken: undefined };
      });
  }, [liffAppType, providerAccount]);
  return useQuery({
    queryKey: ['liff', providerAccount?.id],
    queryFn: query,
    enabled: !!providerAccount,
  });
};
