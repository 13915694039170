import React from 'react';
import { cva } from '../../../../../styled-system/css';
import { Box, HStack, VStack, styled } from '../../../../../styled-system/jsx';
import { circle } from '../../../../../styled-system/patterns';
import { Button } from '../../../../style/button';

export type ProgressStep =
  | 'selectBookingMenu'
  | 'selectSpotDate'
  | 'selectSpotTime';

export const CreateBookingProgress: React.FC<
  { onClick: (clickedTab: ProgressStep) => void } & (
    | {
        useBookingMenu: true;
        activeStep: ProgressStep;
      }
    | {
        useBookingMenu: false;
        activeStep: Omit<ProgressStep, 'selectBookingMenu'>;
      }
  )
> = ({ useBookingMenu, activeStep, onClick }) => {
  return (
    <HStack alignItems={'center'} justifyContent={'center'}>
      {useBookingMenu && (
        <>
          <ProgressCircleContent
            index={1}
            title={'メニューを選ぶ'}
            isActive={activeStep === 'selectBookingMenu'}
            onClick={() =>
              activeStep === 'selectBookingMenu'
                ? undefined
                : onClick('selectBookingMenu')
            }
          />
          <ProgressConnector marginBottom={'32px'} width={'48px'} />
        </>
      )}
      <ProgressCircleContent
        index={useBookingMenu ? 2 : 1}
        title={'日にちを選ぶ'}
        isActive={activeStep === 'selectSpotDate'}
        onClick={() =>
          activeStep === 'selectSpotDate'
            ? undefined
            : onClick('selectSpotDate')
        }
      />
      <ProgressConnector marginBottom={'32px'} width={'48px'} />
      <ProgressCircleContent
        index={useBookingMenu ? 3 : 2}
        title={'時間を選ぶ'}
        isActive={activeStep === 'selectSpotTime'}
        onClick={() =>
          activeStep === 'selectSpotTime'
            ? undefined
            : onClick('selectSpotTime')
        }
      />
    </HStack>
  );
};

const ProgressCircleContent: React.FC<{
  onClick: () => void;
  index: 1 | 2 | 3;
  title: string;
  isActive: boolean;
}> = ({ onClick, index, title, isActive }) => {
  return (
    <VStack alignItems={'center'} justifyContent={'center'} width={'3em'}>
      <ProgressCircle
        variant={isActive ? 'active' : 'notActive'}
        onClick={onClick}
      >
        {index}
      </ProgressCircle>
      <Box width={'max-content'} textAlign={'center'} fontSize={'12px'}>
        {title}
      </Box>
    </VStack>
  );
};

const recipe = cva({
  base: {
    // borderWidth: '1px',
    padding: '11px',
    width: '32px',
    height: '32px',
  },
  variants: {
    variant: {
      active: {
        backgroundColor: {
          base: 'liff.progress.active.base',
          _hover: 'liff.progress.active.hover',
          _active: 'liff.progress.active.active',
        },
        color: 'white',
      },
      notActive: {
        borderWidth: '2px',
        borderColor: 'liff.border.gray',
        backgroundColor: {
          base: 'liff.progress.notActive.base',
          _hover: 'liff.progress.notActive.hover',
          _active: 'liff.progress.notActive.active',
        },
        color: 'black',
      },
    },
  },
});

const ProgressCircle: React.FC<{
  variant: 'active' | 'notActive';
  children: React.ReactNode;
  onClick: () => void;
}> = ({ variant, onClick, children }) => {
  return (
    <Button onClick={onClick} className={circle(recipe.raw({ variant }))}>
      {children}
    </Button>
  );
};

const ProgressConnector = styled('div', {
  base: {
    width: '48px',
    borderWidth: '2px',
    borderColor: 'liff.border.gray',
  },
});
