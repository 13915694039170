import { sleep } from '@pochico/shared';
import React, { MouseEventHandler } from 'react';
import { styled } from '../../../styled-system/jsx';
import { Button } from '../../style/button';

export const RefreshButton: React.FC<{ width?: string; height?: string }> = ({
  width,
  height,
}) => {
  const [refreshing, setRefreshing] = React.useState(false);
  const refresh: MouseEventHandler<HTMLButtonElement> =
    React.useCallback(async () => {
      if (refreshing) return;
      setRefreshing(true);
      return sleep(1000).then(() => {
        localStorage.clear();
        window.location.reload();
      });
    }, [refreshing]);

  return (
    <Button visual={'transparent'} onClick={refresh}>
      <styled.svg
        animation={refreshing ? 'spin' : undefined}
        cursor={'pointer'}
        enableBackground="new 0 0 41 34"
        width={width || '41px'}
        height={height || '34px'}
        id="Layer_1"
        version="1.1"
        viewBox="0 0 41 34"
        // xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M33.949,16C33.429,7.08,26.051,0,17,0C7.611,0,0,7.611,0,17s7.611,17,17,17v-6c-6.075,0-11-4.925-11-11  S10.925,6,17,6c5.737,0,10.443,4.394,10.949,10h-6.849L31,25.899L40.899,16H33.949z"
          fill="#231F20"
        />
      </styled.svg>
    </Button>
  );
};
