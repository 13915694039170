import type { BookingMenu, ProviderAccount } from '@pochico/shared';
import { chunk, dateStringWithWeekDayShort } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  VStack,
} from '../../../../../styled-system/jsx';
import { SelectButton } from '../../../../style/button';
import { BackLink } from '../../../ui/BackLink';
import { Spinner } from '../../../ui/Spinner';
import { SpotDate, useFetchSpotDates } from './hooks';

export const SelectSpotDateView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  onSelected: (spotDate: SpotDate) => void;
  onCancel: () => void;
}> = ({ providerAccount, bookingMenu, onSelected, onCancel }) => {
  const query = useFetchSpotDates(providerAccount, bookingMenu);

  if (query.status === 'loading') {
    return <Spinner />;
  } else if (query.error || !query.data || !('spotDates' in query.data)) {
    return <Box>エラー {`${query.error || query.data?.error}`}</Box>;
  }

  const dates = chunk(query.data.spotDates, 14).map((chunkedSpotDates) => {
    const dateFrom = chunkedSpotDates[0].date;
    const dateTo = chunkedSpotDates[chunkedSpotDates.length - 1].date;
    return (
      <VStack
        key={`${dateFrom}-${dateTo}`}
        backgroundColor={'white'}
        borderRadius={'24px'}
        alignItems={'flex-start'}
        padding={'20px'}
        height={'full'}
      >
        <Box fontWeight={'bold'} color={'liff.text.lightgreen'}>
          予約日程を選ぶ
        </Box>
        <Divider
          orientation={'horizontal'}
          width={'full'}
          color={'liff.border.gray'}
        />
        <Box
          fontSize={{ base: 'xl', lg: '2xl' }}
          fontWeight={'bold'}
          whiteSpace={'nowrap'}
        >
          {dayjs(dateFrom).format('M月D日')} 〜 {dayjs(dateTo).format('M月D日')}
        </Box>
        <Grid columns={2} width={'max-content'}>
          {chunkedSpotDates.map((spotDate) => (
            <GridItem width={'full'} key={spotDate.date}>
              <SelectableSpotDate
                spotDate={spotDate}
                onSelected={() => onSelected(spotDate)}
              />
            </GridItem>
          ))}
        </Grid>
      </VStack>
    );
  });

  return (
    <Flex
      direction={'column'}
      alignItems={'flex-start'}
      w={{ base: '100vw', lg: 'fit-content' }}
      alignSelf={{ base: 'flex-start', lg: 'center' }}
      paddingX={'20px'}
      overflowX={'auto'}
    >
      {dates.length > 0 ? (
        <HStack
          // justifyContent={'flex-start'}
          alignItems={'flex-start'}
          gap={'16px'}
        >
          {dates}
        </HStack>
      ) : (
        <Box textAlign={'center'} width={'full'}>
          予約可能な日がありません
        </Box>
      )}
      <Box marginTop={'16px'}>
        <BackLink onClick={onCancel}>メニュー選択に戻る</BackLink>
      </Box>
    </Flex>
  );
};

const SelectableSpotDate: React.FC<{
  spotDate: SpotDate;
  onSelected: (spotDate: SpotDate) => void;
}> = ({ spotDate, onSelected }) => {
  return (
    <SelectButton
      variant={spotDate.isInStock ? 'selectable' : 'notSelectable'}
      borderRadius={'8px'}
      width={'full'}
      textAlign={'center'}
      onClick={() => onSelected(spotDate)}
    >
      <div>{dateStringWithWeekDayShort(dayjs(spotDate.date))}</div>
    </SelectButton>
  );
};
