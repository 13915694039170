import { Box, HStack, VStack, styled } from '../../../styled-system/jsx';

export type TabType = 'new' | 'show';
export const TabLink: React.FC<{
  activeTab: TabType;
  onClick: (clickedTab: TabType) => void;
}> = ({ activeTab, onClick }) => {
  return (
    <VStack gap={0} zIndex={1}>
      <HStack
        justifyContent={'center'}
        alignItems={'flex-end'}
        width={'100vw'}
        gap={'8px'}
      >
        <TabButton
          variant={activeTab === 'new' ? 'active' : 'notActive'}
          onClick={() => onClick('new')}
        >
          新しく予約する
        </TabButton>
        <TabButton
          variant={activeTab === 'show' ? 'active' : 'notActive'}
          onClick={() => onClick('show')}
        >
          予約の確認
        </TabButton>
      </HStack>
      <Box
        width={'100%'}
        borderColor={'liff.border.blue'}
        borderWidth={'1px'}
      />
    </VStack>
  );
};

const TabButton = styled('button', {
  base: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottomLeftRadius: 'none',
    borderBottomRightRadius: 'none',
    borderLeftWidth: '2px',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    paddingY: '8px',
    // paddingX: '32px',
    backgroundColor: '#F4F4F4',
    fontSize: '16px',
    minWidth: '8em',
    width: '10em',
    boxSizing: 'content-box',
    marginBottom: '-2px',
    borderBottomWidth: '2px',
  },
  variants: {
    variant: {
      active: {
        borderTopColor: 'liff.border.blue',
        borderLeftColor: 'liff.border.blue',
        borderRightColor: 'liff.border.blue',
        borderBottomColor: '#F4F4F4',
        color: 'liff.text.blue',
        cursor: 'default',
        zIndex: 1, // 下側の枠線を消す(whiteを重ねる)ために必要
        fontWeight: 'bold',
      },
      notActive: {
        borderTopColor: 'liff.border.gray',
        borderLeftColor: 'liff.border.gray',
        borderRightColor: 'liff.border.gray',
        borderBottomColor: 'liff.background.gray',
        // borderBottomColor: 'liff.border.blue',
        color: 'liff.text.gray',
        cursor: 'pointer',
        marginBottom: '-2px',
      },
    },
  },
});
