import {
  Booking,
  BookingMenu,
  ProviderAccount,
  waitAtLeast,
} from '@pochico/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useLiff } from '../useLiff';

export type SpotDate = {
  date: string;
  isInStock: boolean;
  bookingMenuId: string;
};
export type SpotTime = {
  bookingMenuId: string;
  baseDate: string;
  date: string;
  timeList: { spotId: string; time: string; isInStock: boolean }[];
};
const defaultDelay = 300;

// const fetch = fetchWithTimeout;

export const useStartBooking = (providerAccount: ProviderAccount) => {
  const { accessToken } = useLiff();
  const startBooking = React.useCallback(
    async (
      providerAccount: ProviderAccount
    ): Promise<
      | { error: string }
      | { useBookingMenus: true; bookingMenus: BookingMenu[] }
      | {
          useBookingMenus: false;
          bookingMenu: BookingMenu;
          spotDates: SpotDate[];
        }
    > => {
      try {
        const res = await waitAtLeast(
          fetch(
            `${import.meta.env.VITE_API_ENDPOINT}/liff/${
              providerAccount.id
            }/createBooking/start`,
            {
              // timeoutMillis: 3000,
              cache: 'no-store',
              method: 'post',
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          ),
          defaultDelay
        );

        const json = (await res.json()) as
          | { ok: false; error: string }
          | { ok: true; bookingMenus: BookingMenu[] }
          | { ok: true; bookingMenu: BookingMenu; spotDates: SpotDate[] };

        if (!json.ok) {
          console.error(`startBooking failed`, { json });
          return {
            error: json.error,
          };
        }
        if ('bookingMenus' in json) {
          return {
            useBookingMenus: true,
            bookingMenus: json.bookingMenus,
          };
        } else {
          return {
            useBookingMenus: false,
            bookingMenu: json.bookingMenu,
            spotDates: json.spotDates,
          };
        }
      } catch (e) {
        console.error(`failed to start booking ${e}`, { error: e });
        return {
          error: `failed to start booking ${e}`,
        };
      }
    },
    [accessToken]
  );

  return useQuery({
    queryKey: ['startBooking', providerAccount.id],
    queryFn: () => startBooking(providerAccount),
    suspense: true,
  });
};

export const useFetchSpotDates = (
  providerAccount: ProviderAccount,
  bookingMenu: BookingMenu
) => {
  const { accessToken } = useLiff();
  const fetchSpotDates = React.useCallback(
    async (providerAccount: ProviderAccount, bookingMenu: BookingMenu) => {
      if (!providerAccount) {
        return;
      }
      return waitAtLeast(
        fetch(
          `${import.meta.env.VITE_API_ENDPOINT}/liff/${
            providerAccount.id
          }/createBooking/fetchSpotDates`,
          {
            // timeoutMillis: 3000,
            cache: 'no-store',
            method: 'post',
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              bookingMenuId: bookingMenu.id,
            }),
          }
        ),
        defaultDelay
      )
        .then(async (res) => {
          const json = (await res.json()) as
            | { ok: false; error: string }
            | { ok: true; spotDates: SpotDate[] };

          if (json.ok) {
            return {
              spotDates: json.spotDates,
            };
          } else {
            console.error(`selectSpotDate failed`, { bookingMenu, json });
            return {
              error: json.error,
            };
          }
        })
        .catch((e) => {
          console.error(e);
          return {
            error: `failed to select spot date ${e}`,
          };
        });
    },
    [accessToken]
  );
  return useQuery({
    queryKey: ['fetchSpotDates', providerAccount.id, bookingMenu.id],
    queryFn: () => fetchSpotDates(providerAccount, bookingMenu),
    suspense: true,
  });
};

export const useFetchSpotTimes = (
  providerAccount: ProviderAccount,
  bookingMenu: BookingMenu,
  date: SpotDate['date']
) => {
  const { accessToken } = useLiff();
  const fetchSpotTimes = React.useCallback(
    async (
      providerAccount: ProviderAccount,
      bookingMenu: BookingMenu,
      date: SpotDate['date']
    ) => {
      if (!providerAccount) {
        return;
      }
      return waitAtLeast(
        fetch(
          `${import.meta.env.VITE_API_ENDPOINT}/liff/${
            providerAccount.id
          }/createBooking/fetchSpotTimes`,
          {
            // timeoutMillis: 3000,
            cache: 'no-store',
            method: 'post',
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              bookingMenuId: bookingMenu.id,
              date: date,
            }),
          }
        ),
        defaultDelay
      )
        .then(async (res) => {
          const json = (await res.json()) as
            | { ok: false; error: string }
            | { ok: true; spotTimes: SpotTime[] };

          if (json.ok) {
            return {
              spotTimes: json.spotTimes,
            };
          } else {
            console.error(`selectSpotTime failed`, {
              providerAccountId: providerAccount.id,
              bookingMenuId: bookingMenu.id,
              date,
              json,
            });
            return {
              error: json.error,
            };
          }
        })
        .catch((e) => {
          console.error({
            error: e,
            providerAccountId: providerAccount.id,
            bookingMenuId: bookingMenu.id,
            date,
          });
          return {
            error: `failed to select spot time ${e}`,
          };
        });
    },
    [accessToken]
  );
  return useQuery({
    queryKey: ['fetchSpotTimes', providerAccount.id, bookingMenu.id, date],
    queryFn: () => fetchSpotTimes(providerAccount, bookingMenu, date),
    suspense: true,
  });
};

export const useSubmitBooking = () => {
  const submitBooking = React.useCallback(
    async (
      providerAccount: ProviderAccount,
      bookingMenu: BookingMenu,
      spotId: string,
      accessToken: string
    ) => {
      if (!providerAccount || !accessToken) {
        return;
      }
      try {
        const res = await waitAtLeast(
          fetch(
            `${import.meta.env.VITE_API_ENDPOINT}/liff/${
              providerAccount.id
            }/createBooking/submit`,
            {
              // timeoutMillis: 3000,
              cache: 'no-store',
              method: 'post',
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                bookingMenuId: bookingMenu.id,
                spotId,
              }),
            }
          ),
          defaultDelay
        );
        const json = (await res.json()) as
          | { ok: false; error: string }
          | { ok: true; booking: Booking; successMessage: string };

        if (json.ok) {
          return {
            booking: json.booking,
            successMessage: json.successMessage,
          };
        } else {
          console.error(`selectSpotTime failed`, {
            bookingMenuId: bookingMenu.id,
            spotId,
            json,
          });
          return {
            error: json.error,
          };
        }
      } catch (e) {
        console.error(`failed to select spot time ${e}`, {
          bookingMenuId: bookingMenu.id,
          spotId,
        });
        return {
          error: `failed to select spot time ${e}`,
        };
      }
    },
    []
  );
  return useMutation(
    (data: {
      providerAccount: ProviderAccount;
      bookingMenu: BookingMenu;
      spotId: string;
      accessToken: string;
    }) =>
      submitBooking(
        data.providerAccount,
        data.bookingMenu,
        data.spotId,
        data.accessToken
      )
    // suspense: true,
  );
};

// Botと共有している
export type BookingViewParams = {
  index: number; // 件数表示のため
  bookingId: string;
  title: string | undefined;
  dateTime: string;
  needBookingMenu: boolean;
  status: 'cancelable' | 'not_cancelable' | 'past';
};
export const useFetchBooking = (
  providerAccount: ProviderAccount,
  accessToken: string
) => {
  const fetchBookings = React.useCallback(async () => {
    return waitAtLeast(
      fetchWithTimeout(
        `${import.meta.env.VITE_API_ENDPOINT}/liff/${
          providerAccount.id
        }/bookings`,
        {
          timeoutMillis: 3000,
          cache: 'no-store',
          method: 'get',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
      defaultDelay
    )
      .then(async (res) => {
        const json = (await res.json()) as { bookings: BookingViewParams[] };
        return { bookings: json.bookings };
      })
      .catch((e) => {
        const message = e.name === 'AbortError' ? 'timeout' : `${e}`;
        console.error(`failed to fetch bookings ${e}`, { error: e, message });
        return Promise.reject({
          error: `failed to fetch bookings due to ${message}`,
        });
      });
  }, [accessToken, providerAccount.id]);

  return useQuery({
    queryKey: ['fetchBookings', providerAccount.id, accessToken],
    queryFn: () => fetchBookings(),
    suspense: true,
  });
};

// Botと共有している
export const useCancelBooking = () => {
  const cancelBooking = React.useCallback(
    async (input: {
      providerAccount: ProviderAccount;
      bookingId: string;
      accessToken: string;
    }) => {
      const { providerAccount, bookingId, accessToken } = input;
      return waitAtLeast(
        fetchWithTimeout(
          `${import.meta.env.VITE_API_ENDPOINT}/liff/${
            providerAccount.id
          }/bookings`,
          {
            timeoutMillis: 3000,
            cache: 'no-store',
            method: 'delete',
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              bookingId,
            }),
          }
        ),
        1500
      )
        .then(async (res) => {
          const json = (await res.json()) as
            | { ok: true }
            | { ok: false; error: string };
          return json;
        })
        .catch((e) => {
          const message = e.name === 'AbortError' ? 'timeout' : `${e}`;
          console.error(`failed to cancel booking ${e}`, {
            error: e,
            message,
            providerAccountId: providerAccount.id,
            bookingId,
          });
          return Promise.reject({
            error: `failed to cancel booking due to ${message}`,
          });
        });
    },
    []
  );

  return useMutation(cancelBooking);
};

export const fetchWithTimeout = async (
  url: string,
  options: RequestInit & { timeoutMillis: number }
) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (options?.signal) {
    options.signal.addEventListener('abort', () => controller.abort());
  }
  const timeout = setTimeout(() => controller.abort(), options.timeoutMillis);
  return promise.finally(() => clearTimeout(timeout));
};
