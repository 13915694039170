import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Box, VStack } from '../../../styled-system/jsx';
import { Spinner } from './Spinner';

export const SuspenseWithErrorBoundary: React.FC<{
  fallback?: React.ReactElement;
  children: React.ReactNode;
}> = ({ fallback, children }) => {
  return (
    <ErrorBoundary fallbackRender={(prop) => <ErrorFallback {...prop} />}>
      <React.Suspense fallback={fallback || <Spinner />}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
};
const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  return (
    <VStack width={'100%'} justifyContent={'center'}>
      <Box fontSize={'xl'}>エラーが発生しました</Box>
      <Box>{typeof error === 'object' ? JSON.stringify(error) : error}</Box>
    </VStack>
  );
};
