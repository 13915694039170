// import { Text } from '@chakra-ui/react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '../../../../styled-system/css';
import { Box, HStack, VStack } from '../../../../styled-system/jsx';
import { Footer } from '../../ui/Footer';
import { Spinner } from '../../ui/Spinner';
import { SuspenseWithErrorBoundary } from '../../ui/SuspenseWithErrorBoundary';
import { TabLink, TabType } from '../../ui/TabLink';
import { AuthProviderContainer } from './AuthProviderContainer';
import { Bookings } from './ShowBookings';
import { CreateBooking } from './createBooking/CreateBooking';
import { useLiff } from './useLiff';

export const PochicoLiff: React.FC<{ providerAccountId: string }> = ({
  providerAccountId,
}) => {
  return (
    <Box>
      <SuspenseWithErrorBoundary
        fallback={
          <HStack>
            <div>読み込み中...</div>
            <Spinner />
          </HStack>
        }
      >
        <AuthProviderContainer
          providerAccountId={providerAccountId}
          liffAppType={'bookingPage'}
        >
          <Body />

          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            className={css({
              minWidth: 'max-content',
            })}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            theme="colored"
          />
        </AuthProviderContainer>
      </SuspenseWithErrorBoundary>
      <Footer />
    </Box>
  );
};

const Body: React.FC = () => {
  const { providerAccount, status, isLineLoggedIn, lineLogin } = useLiff();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = React.useState<TabType>(() => {
    if (
      searchParams &&
      searchParams.has('tab') &&
      ['new', 'show'].includes(searchParams.get('tab') || 'new')
    ) {
      return searchParams.get('tab') as TabType;
    } else {
      return 'new';
    }
  });

  React.useEffect(() => {
    document.title = `${providerAccount?.displayName} - かんたん予約ポチコ -`;
  }, [providerAccount?.displayName]);

  React.useEffect(() => {
    if (!isLineLoggedIn && status === 'success') {
      lineLogin();
    }
  }, [isLineLoggedIn, lineLogin, status]);

  return (
    <VStack
      width={'100%'}
      height={'100vh'}
      minHeight={'-webkit-fill-available'}
      overflow={'auto'}
      backgroundColor={'liff.background.gray'}
      paddingTop={'16px'}
      gap={0}
      paddingBottom={'60px'}
    >
      <Box width={'100%'} justifyContent={'center'} marginBottom={'24px'}>
        <TabLink
          activeTab={activeTab}
          onClick={(clickedTab: TabType) => {
            setActiveTab(clickedTab);
          }}
        />
      </Box>
      {isLineLoggedIn && providerAccount ? (
        <SuspenseWithErrorBoundary>
          <>
            {activeTab === 'new' ? (
              <CreateBooking providerAccount={providerAccount} />
            ) : (
              <Bookings providerAccount={providerAccount} />
            )}
          </>
        </SuspenseWithErrorBoundary>
      ) : (
        <Spinner />
      )}
    </VStack>
  );
};
