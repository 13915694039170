import { ProviderAccount } from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

export const useFetchProviderAccount = (providerAccountId: string) => {
  const fetchProviderAccount = React.useCallback(
    (providerAccountId: string) => {
      return fetch(
        `${import.meta.env.VITE_API_ENDPOINT}/liff/${providerAccountId}`,
        {
          cache: 'no-store',
          method: 'get',
          headers: {
            'Content-type': 'application/json',
          },
        }
      ).then(async (res) => {
        const json = (await res.json()) as
          | { ok: false; error: string }
          | { ok: true; providerAccount: ProviderAccount };
        console.log(
          `[useFetchProviderAccount ${providerAccountId}]response: ${res}`,
          { res }
        );
        if (json.ok) {
          // plan等による事前条件のチェックはAPI側で行っているので、ここではチェックしない
          return { providerAccount: json.providerAccount };
        } else {
          return Promise.reject(json.error);
        }
      });
    },
    []
  );

  return useQuery({
    queryKey: ['providerAccount', providerAccountId],
    queryFn: () => fetchProviderAccount(providerAccountId),
    suspense: true,
  });
};
