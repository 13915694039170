import { css } from '../../../styled-system/css';
import { HStack } from '../../../styled-system/jsx';
import { RefreshButton } from './RefreshButton';

export const Footer = () => {
  // productionでも出しちゃう
  const showBuildVersion =
    true || import.meta.env.DEV || import.meta.env.MODE === 'development';
  return (
    <footer
      className={css({
        fontSize: 'xx-small',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '6px',
        textAlign: 'end',
      })}
    >
      <HStack justifyContent={'flex-end'} gap={'4px'} alignItems={'center'}>
        Powered by かんたん予約ぽちこ
        {showBuildVersion ? ` [${__COMMIT_HASH__}]` : ''}
        <RefreshButton width={'18px'} />
      </HStack>
    </footer>
  );
};
