import { ProviderAccount, dateTimeStringWithWeekDay } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { toast } from 'react-toastify';
import { css } from '../../../../styled-system/css';
import {
  Box,
  Divider,
  Flex,
  HStack,
  VStack,
} from '../../../../styled-system/jsx';
import { SelectButton } from '../../../style/button';
import { ButtonWithLoading } from '../../ui/Button';
import { Spinner } from '../../ui/Spinner';
import { SuspenseWithErrorBoundary } from '../../ui/SuspenseWithErrorBoundary';
import {
  BookingViewParams,
  useCancelBooking,
  useFetchBooking,
} from './createBooking/hooks';
import { useLiff } from './useLiff';

export const Bookings: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const {
    accessToken,
    isLineLoggedIn,
    lineLogin,
    status: liffStatus,
  } = useLiff();

  if (!accessToken || !isLineLoggedIn) {
    return (
      <VStack
        className={css({
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        予約を確認するにはログインしてください
        <ButtonWithLoading
          borderRadius={'2xl'}
          paddingY={'8px'}
          paddingX={'32px'}
          onClick={() => lineLogin()}
          isLoading={liffStatus === 'loading'}
        >
          LINE Login
        </ButtonWithLoading>
      </VStack>
    );
  }

  return (
    <SuspenseWithErrorBoundary>
      <BookingsInternal
        providerAccount={providerAccount}
        accessToken={accessToken}
      />
    </SuspenseWithErrorBoundary>
  );
};

const BookingsInternal: React.FC<{
  providerAccount: ProviderAccount;
  accessToken: string;
}> = ({ providerAccount, accessToken }) => {
  const { refetch, data, status, error } = useFetchBooking(
    providerAccount,
    accessToken
  );
  const { mutateAsync } = useCancelBooking();
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const onCancel = React.useCallback(
    async (booking: BookingViewParams) => {
      if (!providerAccount || !accessToken) {
        return;
      }
      return mutateAsync({
        providerAccount,
        bookingId: booking.bookingId,
        accessToken: accessToken,
      })
        .then((res) => {
          if (res.ok) {
            toast.info(() => (
              <Box>
                予約をキャンセルしました
                <br />
                予約内容: {booking.title}
                <br /> {dateTimeStringWithWeekDay(dayjs(booking.dateTime))}
              </Box>
            ));
            return refetch();
          } else {
            toast.error(() => (
              <Box>
                予約のキャンセルに失敗しました
                <br />
                {res.error}
              </Box>
            ));
            console.error(`error: ${res.error}`, { error: res.error });
            return Promise.reject(res.error);
          }
        })
        .then(() => {
          if (scrollRef.current) {
            // キャンセルしたあとはスクロール位置を戻す
            scrollRef.current.scrollLeft = 0;
          }
          return; // 型合わせ
        });
    },
    [accessToken, mutateAsync, providerAccount, refetch]
  );

  const Body = React.useMemo(() => {
    return () => {
      const bookings = (data?.bookings || []).map((booking) => {
        return (
          <BookingDetail
            key={booking.bookingId}
            booking={booking}
            onCancel={onCancel}
          />
        );
      });
      if (bookings.length > 0) {
        return <HStack gap={'16px'}>{bookings}</HStack>;
      } else {
        return (
          <Flex width={'full'} justifyContent={'center'}>
            予約がありません
          </Flex>
        );
      }
    };
  }, [onCancel, data?.bookings]);

  return (
    <Flex
      direction={'column'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      w={{ base: '100vw', lg: 'fit-content' }}
      alignSelf={{ base: 'flex-start', lg: 'center' }}
      paddingX={'20px'}
      overflowX={'auto'}
      ref={scrollRef}
    >
      {status === 'loading' ? (
        <HStack>
          <Spinner />
          <>Loading... {status}</>
        </HStack>
      ) : status !== 'success' ? (
        <Box>読み込みに失敗しました: {`${error}`}</Box>
      ) : (
        <Body />
      )}
    </Flex>
  );
};

const BookingDetail: React.FC<{
  booking: BookingViewParams;
  onCancel: (booking: BookingViewParams) => Promise<void>;
}> = ({ booking, onCancel }) => {
  const [isCanceling, setIsCanceling] = React.useState(false);
  const onClickCancel = React.useCallback(() => {
    setIsCanceling(true);
    return onCancel(booking).finally(() => {
      setIsCanceling(false);
    });
  }, [booking, onCancel]);
  const disabled = isCanceling || booking.status !== 'cancelable';

  return (
    <VStack
      key={booking.bookingId}
      backgroundColor={isCanceling ? 'gray.50' : 'white'}
      border={isCanceling ? '1px solid gray.500' : 'none'}
      borderRadius={'24px'}
      boxSizing={'border-box'}
      alignItems={'flex-start'}
      padding={'20px'}
      w={'260px'}
    >
      <Box fontWeight={'bold'} color={'liff.text.lightgreen'}>
        予約中 {booking.index + 1}件目
      </Box>
      <Divider
        orientation={'horizontal'}
        width={'full'}
        color={'liff.border.gray'}
      />
      <VStack alignItems={'flex-start'}>
        <Box>{'<日時>'}</Box>
        <Box fontSize={'lg'} fontWeight={'bold'}>
          {dateTimeStringWithWeekDay(dayjs(booking.dateTime))}
        </Box>
      </VStack>
      <VStack alignItems={'flex-start'}>
        <Box>{'<予約内容>'}</Box>
        <Box fontSize={'lg'} fontWeight={'bold'}>
          {booking.title}
        </Box>
      </VStack>

      <SelectButton
        width={'full'}
        variant={disabled ? 'notSelectable' : 'cancel'}
        marginTop={'16px'}
        disabled={disabled}
        onClick={onClickCancel}
      >
        {isCanceling ? <Spinner /> : <>キャンセルする</>}
      </SelectButton>
    </VStack>
  );
};
