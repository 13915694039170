import type { BookingMenu, ProviderAccount } from '@pochico/shared';
import { chunk, dateStringWithWeekDay } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  VStack,
} from '../../../../../styled-system/jsx';
import { SelectButton } from '../../../../style/button';
import { BackLink } from '../../../ui/BackLink';
import { Spinner } from '../../../ui/Spinner';
import { SpotDate, SpotTime, useFetchSpotTimes } from './hooks';

export const SelectSpotTimeView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  spotDate: SpotDate;
  onSelected: (spotTime: SpotTime['timeList'][number]) => void;
  onCancel: () => void;
}> = ({ providerAccount, bookingMenu, spotDate, onSelected, onCancel }) => {
  const query = useFetchSpotTimes(providerAccount, bookingMenu, spotDate.date);
  if (query.status === 'loading') {
    return <Spinner />;
  } else if (query.error || !query.data || !('spotTimes' in query.data)) {
    return <Box>エラー {`${query.error || query.data?.error}`}</Box>;
  }

  const carouselItems = query.data.spotTimes.map((spotTime) => {
    return chunk(spotTime.timeList, 8).map(
      (timeList: SpotTime['timeList'], i) => {
        return (
          <VStack
            key={`${spotTime.date}-${i}`}
            backgroundColor={'white'}
            borderRadius={'24px'}
            alignItems={'flex-start'}
            padding={'20px'}
            height={'full'}
          >
            <Box fontWeight={'bold'} color={'liff.text.lightgreen'}>
              予約日程を選ぶ
            </Box>
            <Divider
              orientation={'horizontal'}
              width={'full'}
              color={'liff.border.gray'}
            />
            <Box
              fontSize={{ base: 'xl', lg: '2xl' }}
              fontWeight={'bold'}
              whiteSpace={'nowrap'}
            >
              {dateStringWithWeekDay(dayjs(spotTime.date))}
            </Box>
            <Grid columns={2} width={'max-content'}>
              {timeList.map((spotTime) => (
                <GridItem key={`${spotTime.spotId}`}>
                  <SelectableSpotTime
                    key={spotTime.spotId}
                    spotTime={spotTime}
                    onSelected={onSelected}
                  />
                </GridItem>
              ))}
            </Grid>
          </VStack>
        );
      }
    );
  });

  return (
    <Flex
      direction={'column'}
      alignItems={'flex-start'}
      w={{ base: '100vw', lg: 'fit-content' }}
      alignSelf={{ base: 'flex-start', lg: 'center' }}
      paddingX={'20px'}
      overflowX={'auto'}
    >
      {carouselItems.length > 0 ? (
        <HStack paddingBottom={'auto'} alignItems={'flex-start'} gap={'16px'}>
          {carouselItems}
        </HStack>
      ) : (
        <Box textAlign={'center'} width={'full'}>
          予約可能な時間がありません
        </Box>
      )}
      <Box marginTop={'16px'}>
        <BackLink onClick={onCancel}>日にち選択に戻る</BackLink>
      </Box>
    </Flex>
  );
};

const SelectableSpotTime: React.FC<{
  spotTime: SpotTime['timeList'][number];
  onSelected: (spotTime: SpotTime['timeList'][number]) => void;
}> = ({ spotTime, onSelected }) => {
  return (
    <SelectButton
      variant={spotTime.isInStock ? 'selectable' : 'notSelectable'}
      disabled={!spotTime.isInStock}
      borderRadius={'8px'}
      onClick={() => onSelected(spotTime)}
      width={'full'}
      textAlign={'center'}
    >
      <div>{spotTime.time}</div>
    </SelectButton>
  );
};
