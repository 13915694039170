import React from 'react';
import { Button } from '../../style/button';
import { Spinner } from './Spinner';
import { UIProps } from './type';

export const ButtonWithLoading: React.FC<
  {
    isLoading?: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement> &
    UIProps
> = ({ children, isLoading, ...props }) => {
  return (
    <Button {...props} disabled={isLoading}>
      {isLoading ? <Spinner /> : children}
    </Button>
  );
};
